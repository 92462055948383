import React from "react";
import { MdVerified } from "react-icons/md";
import { formatSalary } from "@koble/utils/src";
import { Avatar, Flex, Typography } from "antd";

import { useCommonJobCard } from "./CommonJobCardHook";

const { Text, Title } = Typography;

const CommonJobCardHeader = () => {
  const { job, profileImagePlaceholder } = useCommonJobCard();

  const salaryRangeComponent = () => {
    if (
      job.salaryCategory?.salaryCategoryId === "NEVER" ||
      job.salaryCategory?.salaryCategoryId === "NOT_SPECIFIED"
    )
      return "Sin remuneración";

    if (!job.minSalary || !job.maxSalary) return "";

    if (job.minSalary !== job.maxSalary) {
      return `${formatSalary(job.minSalary)} - ${formatSalary(
        job.maxSalary
      )} ${job.salaryCategory?.spanishName.toLowerCase()}`;
    }

    return `${formatSalary(
      job.minSalary
    )} ${job.salaryCategory?.spanishName.toLowerCase()}`;
  };

  return (
    <Flex justify="space-between" style={{ marginBottom: 10 }}>
      <Avatar
        shape="square"
        size={64}
        src={job.business?.profileImageUrl ?? profileImagePlaceholder}
        style={{
          marginRight: 8,
          border: "1px solid #f0f0f0",
          borderColor: "#e8e8e8",
        }}
      />
      <div style={{ flex: 1, minWidth: 0 }}>
        <Title level={5} style={{ marginBottom: 0 }}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {job.title}
          </div>
        </Title>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 13,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {job.business?.name ?? ""}
          </div>
          {job.business?.isVerified && (
            <div style={{ minWidth: 20 }}>
              <MdVerified
                style={{
                  fontSize: 13,
                  color: "#1677FF",
                  marginTop: 5,
                  marginRight: 4,
                  float: "right",
                }}
              />
            </div>
          )}
        </div>
        <Text style={{ fontSize: 13 }}>{salaryRangeComponent()}</Text>
      </div>
    </Flex>
  );
};

export default CommonJobCardHeader;
