import React, { ReactNode } from "react";
import { Job } from "@koble/graphql";
import { Card, Divider, GlobalToken, theme } from "antd";

import CommonAdaptiveLink from "../../CommonAdaptiveLink";

import CommonJobCardBody from "./CommonJobCardBody";
import CommonJobCardFooter from "./CommonJobCardFooter";
import CommonJobCardHeader from "./CommonJobCardHeader";
import {
  CommonJobCardContextProps,
  CommonJobCardProvider,
} from "./CommonJobCardHook";
import CommonJobCardTags from "./CommonJobCardTags";

type ExtendedGlobalToken = GlobalToken & {
  boxShadowCard?: string;
};

const CommonJobCard = ({
  extraContent,
  ssrUrl,
  clientUrl,
  selectedJob,
  hoverable,
  onClick,
  ...props
}: {
  extraContent?: ReactNode;
  ssrUrl: string;
  clientUrl?: string;
  selectedJob?: boolean;
  hoverable?: boolean;
  onClick?: (j: Job) => void;
} & CommonJobCardContextProps) => {
  const token = theme.useToken().token as ExtendedGlobalToken;

  return (
    <CommonJobCardProvider {...props}>
      <Card
        hoverable={hoverable}
        style={{
          border: selectedJob ? "2px solid #0984e3" : undefined,
          boxShadow: selectedJob ? token.boxShadowCard : undefined,
        }}
        styles={{ body: { padding: 0 } }}
      >
        <CommonAdaptiveLink
          ssrUrl={ssrUrl}
          clientUrl={clientUrl}
          scroll={false}
        >
          <div
            role="button"
            tabIndex={-1}
            onClick={() => onClick && onClick(props.job)}
            style={{
              padding: 15,
              ...(extraContent && { paddingBottom: 8 }),
            }}
          >
            <CommonJobCardTags />
            <CommonJobCardHeader />
            <CommonJobCardBody />
            <Divider style={{ marginBottom: 7, marginTop: 10 }} />
            <CommonJobCardFooter />
          </div>
        </CommonAdaptiveLink>
        {extraContent && (
          <div style={{ padding: 15, paddingTop: 0, cursor: "default" }}>
            {extraContent}
          </div>
        )}
      </Card>
    </CommonJobCardProvider>
  );
};

export default CommonJobCard;
