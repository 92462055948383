import React from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tag, Tooltip, Typography } from "antd";

import commonColors from "../../commonColors";
import { CommonSymbology } from "../../commonSymbology";

import { useCommonJobCard } from "./CommonJobCardHook";

const CommonJobCardTags = () => {
  const { job, extraActionsMenu } = useCommonJobCard();
  const columnGap = 8;

  let compatibility = null;

  if (
    job.compatibility &&
    job.compatibility.isProcessed &&
    job.compatibility.score
  ) {
    if (job.compatibility.score > 0) {
      compatibility = (
        <Tooltip
          title={`Tienes ${job.compatibility.score}% de compatibilidad con esta vacante: ${job.compatibility.userStudentInsight}`}
        >
          <Space direction="horizontal" size={4}>
            <CommonSymbology.Compatibility />
            <Typography.Text
              style={{
                color: commonColors.compatibility,
                fontWeight: 600,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {job.compatibility.score}%
            </Typography.Text>
          </Space>
        </Tooltip>
      );
    } else {
      let color = "";
      let label = "";
      let title = "";

      if (job.compatibility.score === -100) {
        // blue
        color = "#1890ff";
        label = "S";
        title = "Muy recomendado";
      } else if (job.compatibility.score === -80) {
        // green
        color = "#52c41a";
        label = "A";
        title = "Recomendado";
      } else if (job.compatibility.score === -60) {
        // yellow
        color = "#fadb14";
        label = "B";
        title = "Poco recomendado";
      } else if (job.compatibility.score === -40) {
        // orange
        color = "#fa8c16";
        label = "C";
        title = "No recomendado";
      }

      compatibility = (
        <Tooltip
          title={
            <>
              <div>{title}</div>
              {job.compatibility.userStudentInsight &&
                job.compatibility.userStudentInsight.split("\n").map((i, k) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <div key={k}>{i}</div>;
                })}
            </>
          }
        >
          <Space direction="horizontal" size={4}>
            <CommonSymbology.Compatibility twoToneColor={color} />
            <Typography.Text
              style={{
                color,
                fontWeight: 600,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {label}
            </Typography.Text>
          </Space>
        </Tooltip>
      );
    }
  }

  return (
    <div style={{ marginBottom: 10, display: "flex", columnGap }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: 1,
          flexShrink: 1, // Allow the container tag to shrink
          minWidth: 0, // Prevent overflow by allowing it to shrink
          columnGap,
        }}
      >
        {job.area && (
          <Tooltip title={job.area.spanishName}>
            <Tag
              // color={stringToColor(job.area.areaId)}
              style={{
                flexShrink: 1, // Allow the area tag to shrink
                minWidth: 0, // Prevent overflow by allowing it to shrink
                overflow: "hidden", // Ensure no overflow text is visible
                textOverflow: "ellipsis", // Show ellipsis if text is too long
                marginRight: 0,
              }}
            >
              {job.area.spanishName.length > 30
                ? `${job.area.spanishName.slice(0, 30)}...`
                : job.area.spanishName}
            </Tag>
          </Tooltip>
        )}
        <Space size={6}>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {compatibility}
          </div>
        </Space>
      </div>
      {extraActionsMenu && (
        <Dropdown
          menu={{
            ...extraActionsMenu,
            items: extraActionsMenu.items?.map((item) => {
              // remove the 'loading' since it's not part of the original ant menu item
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { loading: _, ...rest } = item;
              return { ...rest };
            }),
          }} // This is a partial implementation
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button
            loading={extraActionsMenu.items?.some((item) => item.loading)}
            type="text"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault(); // stop the card click event
            }}
            icon={<MoreOutlined />}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default CommonJobCardTags;
