import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, ButtonProps, ConfigProvider } from "antd";

type DiscardButtonStatus = "discarded" | "notDiscarded";

interface DiscardButtonProps extends ButtonProps {
  status?: DiscardButtonStatus;
}

const DiscardButton = ({
  status = "notDiscarded",
  ...props
}: DiscardButtonProps) => {
  const discarded = status === "discarded";
  const buttonText = discarded ? "Descartado" : "Descartar";
  const width = discarded ? "100%" : "50%";

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimaryHover: "#FB4C4F",
            colorPrimaryActive: "#FB4C4F",
          },
        },
      }}
    >
      <Button
        icon={<DeleteOutlined />}
        type="dashed"
        style={{ width }}
        disabled={discarded}
        {...props}
      >
        {buttonText}
      </Button>
    </ConfigProvider>
  );
};

export default DiscardButton;
